import React, { useEffect } from 'react';
import { Music, Play, Pause } from 'lucide-react';
import { useRadioStore } from '../../store/radioStore';
import { useMusicUploadStore } from '../../store/musicUploadStore';
import { motion } from 'framer-motion';

export function CommunityChannel() {
  const { currentTrack, isPlaying, setPlaying } = useRadioStore();
  const { getApprovedTracks } = useMusicUploadStore();
  const approvedTracks = getApprovedTracks();

  useEffect(() => {
    if (!currentTrack && approvedTracks.length > 0) {
      setPlaying(true);
    }
  }, [approvedTracks, currentTrack]);

  return (
    <div className="bg-gray-900 rounded-xl p-6 space-y-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div className="w-12 h-12 bg-purple-500 rounded-lg flex items-center justify-center">
            <Music className="w-6 h-6 text-white" />
          </div>
          <div>
            <h2 className="text-xl font-bold">Community Radio</h2>
            <p className="text-gray-400">Music from our community artists</p>
          </div>
        </div>
        <button
          onClick={() => setPlaying(!isPlaying)}
          className="w-12 h-12 rounded-full bg-purple-500 flex items-center justify-center hover:bg-purple-600 transition"
        >
          {isPlaying ? <Pause className="w-6 h-6" /> : <Play className="w-6 h-6" />}
        </button>
      </div>

      {currentTrack && isPlaying && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-purple-500/10 border border-purple-500/20 rounded-lg p-4"
        >
          <div className="text-sm text-purple-400 mb-1">Now Playing</div>
          <div className="font-medium text-lg">{currentTrack.title}</div>
          <div className="text-gray-400">{currentTrack.artist}</div>
        </motion.div>
      )}

      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Community Playlist</h3>
        {approvedTracks.length === 0 ? (
          <div className="text-center py-8 text-gray-400">
            <Music className="w-12 h-12 mx-auto mb-4 opacity-50" />
            <p>No tracks available yet</p>
            <p className="text-sm mt-2">Upload your music to be featured here!</p>
          </div>
        ) : (
          <div className="space-y-2">
            {approvedTracks.map((track) => (
              <div
                key={track.id}
                className="bg-gray-800 rounded-lg p-4 flex items-center justify-between"
              >
                <div>
                  <div className="font-medium">{track.title}</div>
                  <div className="text-sm text-gray-400">{track.artist}</div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-400">
                    {track.playCount} plays
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}