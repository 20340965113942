import React, { useState } from 'react';
import { User, Camera, Mail, Calendar } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

export function UserProfile() {
  const { session } = useAuthStore();
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState(session?.user?.user_metadata?.username || '');

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle profile update logic here
    setIsEditing(false);
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 mb-6">
      <div className="flex items-center gap-6 mb-6">
        <div className="relative">
          <div className="w-24 h-24 rounded-full bg-gray-700 flex items-center justify-center">
            {session?.user?.user_metadata?.avatar_url ? (
              <img
                src={session.user.user_metadata.avatar_url}
                alt="Profile"
                className="w-full h-full rounded-full object-cover"
              />
            ) : (
              <User className="w-12 h-12 text-gray-400" />
            )}
          </div>
          <button className="absolute bottom-0 right-0 p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition">
            <Camera className="w-4 h-4" />
          </button>
        </div>
        
        <div className="flex-1">
          {isEditing ? (
            <form onSubmit={handleUpdateProfile}>
              <input
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 mb-2"
                placeholder="Enter your name"
              />
              <div className="flex gap-2">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 rounded-lg hover:bg-blue-600 transition"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition"
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <>
              <h2 className="text-2xl font-bold mb-1">{displayName || 'User'}</h2>
              <button
                onClick={() => setIsEditing(true)}
                className="text-sm text-blue-400 hover:text-blue-300"
              >
                Edit Profile
              </button>
            </>
          )}
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center gap-3 text-gray-400">
          <Mail className="w-5 h-5" />
          <span>{session?.user?.email}</span>
        </div>
        <div className="flex items-center gap-3 text-gray-400">
          <Calendar className="w-5 h-5" />
          <span>Joined {format(new Date(session?.user?.created_at || Date.now()), 'MMMM yyyy')}</span>
        </div>
      </div>
    </div>
  );
}