import React, { useState, useEffect, useRef } from 'react';
import { Send, Smile } from 'lucide-react';
import { useCommentStore } from '../../store/commentStore';
import { useAuthStore } from '../../store/authStore';
import { motion, AnimatePresence } from 'framer-motion';

interface CommentSectionProps {
  podcastId: string;
}

export function CommentSection({ podcastId }: CommentSectionProps) {
  const [message, setMessage] = useState('');
  const { user } = useAuthStore();
  const { comments, addComment, getComments } = useCommentStore();
  const commentsEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [comments]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user) return;

    addComment({
      podcastId,
      content: message,
      userId: user.id,
      userName: user.name,
      userAvatar: user.avatar,
      timestamp: new Date()
    });

    setMessage('');
  };

  return (
    <div className="border-t border-gray-700 pt-6">
      <h3 className="text-lg font-semibold mb-4">Live Chat</h3>
      
      <div className="bg-gray-800 rounded-lg p-4 mb-4 h-[300px] overflow-y-auto">
        <AnimatePresence initial={false}>
          {getComments(podcastId).map((comment) => (
            <motion.div
              key={comment.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex items-start gap-3 mb-4"
            >
              <img
                src={comment.userAvatar || `https://source.unsplash.com/random/100x100?face&u=${comment.userId}`}
                alt={comment.userName}
                className="w-8 h-8 rounded-full"
              />
              <div className="flex-1">
                <div className="flex items-center gap-2 mb-1">
                  <span className="font-medium">{comment.userName}</span>
                  <span className="text-xs text-gray-400">
                    {new Date(comment.timestamp).toLocaleTimeString()}
                  </span>
                </div>
                <p className="text-gray-300">{comment.content}</p>
              </div>
            </motion.div>
          ))}
          <div ref={commentsEndRef} />
        </AnimatePresence>
      </div>

      <form onSubmit={handleSubmit} className="flex gap-2">
        <div className="relative flex-1">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            className="w-full bg-gray-700 rounded-lg pl-4 pr-10 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="button"
            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
          >
            <Smile className="w-5 h-5" />
          </button>
        </div>
        <button
          type="submit"
          disabled={!message.trim()}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Send className="w-5 h-5" />
        </button>
      </form>
    </div>
  );
}