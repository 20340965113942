import React from 'react';
import { Radio, Play, Users, Heart } from 'lucide-react';
import { usePodcastStore } from '../store/podcastStore';
import { motion } from 'framer-motion';

export function PopularPodcasts() {
  const { podcasts, getLikes } = usePodcastStore();

  // Get approved podcasts sorted by likes
  const popularPodcasts = podcasts
    .filter(podcast => podcast.status === 'approved')
    .sort((a, b) => getLikes(b.id) - getLikes(a.id))
    .slice(0, 4);

  if (popularPodcasts.length === 0) return null;

  return (
    <section className="mb-12">
      <div className="flex items-center gap-2 mb-6">
        <h2 className="text-2xl font-bold">Most Listened Podcasts</h2>
        <Radio className="w-5 h-5 text-purple-400" />
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {popularPodcasts.map((podcast) => (
          <motion.div
            key={podcast.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800 rounded-lg overflow-hidden group hover:bg-gray-700 transition"
          >
            <div className="aspect-square relative">
              <img
                src={podcast.coverImage || `https://source.unsplash.com/random/800x800?podcast&sig=${podcast.id}`}
                alt={podcast.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                <button className="w-12 h-12 rounded-full bg-purple-500 flex items-center justify-center hover:bg-purple-600 transition transform scale-90 group-hover:scale-100">
                  <Play className="w-6 h-6" />
                </button>
              </div>
            </div>
            <div className="p-4">
              <h3 className="font-semibold mb-1 line-clamp-1">{podcast.title}</h3>
              <p className="text-sm text-gray-400 mb-2">{podcast.hostName}</p>
              <div className="flex items-center gap-4 text-sm text-gray-500">
                <span className="flex items-center gap-1">
                  <Users className="w-4 h-4" />
                  {Math.floor(Math.random() * 1000) + 100} listeners
                </span>
                <span className="flex items-center gap-1">
                  <Heart className="w-4 h-4" />
                  {getLikes(podcast.id)} likes
                </span>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
}