import React from 'react';
import { PodcastStream } from '../components/PodcastLive/PodcastStream';
import { PodcastApplication } from '../components/PodcastLive/PodcastApplication';
import { PodcastAdmin } from '../components/PodcastLive/PodcastAdmin';
import { PodcastControls } from '../components/PodcastLive/PodcastControls';
import { useAuthStore } from '../store/authStore';
import { Mic, Radio } from 'lucide-react';

export function PodcastPage() {
  const { user } = useAuthStore();

  return (
    <div className="px-4 py-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <div className="bg-gradient-to-r from-purple-500 to-blue-500 w-12 h-12 rounded-full flex items-center justify-center">
            <Radio className="w-6 h-6 text-white" />
          </div>
          <div>
            <h1 className="text-3xl font-bold">Live Podcasts</h1>
            <p className="text-gray-400">Listen to live podcasts or become a host</p>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <PodcastStream />
          </div>
          <div className="space-y-8">
            <PodcastControls />
            <PodcastApplication />
            {user?.isAdmin && <PodcastAdmin />}
          </div>
        </div>
      </div>
    </div>
  );
}