import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Radio, Mic, Newspaper, Search, Upload, Menu, Crown, DollarSign, LogOut, User } from 'lucide-react';
import { SearchOverlay } from './SearchOverlay';
import { MobileMenu } from './MobileMenu';
import { cn } from '../lib/utils';
import { useAuthStore } from '../store/authStore';

export function Navigation() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { session, signOut } = useAuthStore();

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 bg-black/95 backdrop-blur-lg border-b border-white/10 z-50">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Link to="/" className="flex items-center gap-2">
                <Radio className="w-8 h-8 text-blue-500" />
                <span className="text-xl font-bold">AI Radio</span>
              </Link>
            </div>
            
            <div className="hidden md:flex items-center gap-6">
              <Link 
                to="/" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Mic className="w-5 h-5" />
                Home
              </Link>
              <Link 
                to="/news" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/news' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Newspaper className="w-5 h-5" />
                News
              </Link>
              <Link 
                to="/plans" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/plans' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Crown className="w-5 h-5" />
                Plans
              </Link>
              <Link 
                to="/submit-ad" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/submit-ad' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <DollarSign className="w-5 h-5" />
                Submit Ad
              </Link>
              <Link 
                to="/upload" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/upload' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Upload className="w-5 h-5" />
                Upload Music
              </Link>
            </div>

            <div className="flex items-center gap-4">
              <button 
                onClick={() => setIsSearchOpen(true)} 
                className="p-2 hover:bg-white/10 rounded-full transition"
              >
                <Search className="w-5 h-5" />
              </button>
              
              {session ? (
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-2 px-3 py-1 bg-gray-800 rounded-full">
                    <User className="w-4 h-4 text-gray-400" />
                    <span className="text-sm">{session.user.email}</span>
                  </div>
                  <button
                    onClick={handleSignOut}
                    className="p-2 hover:bg-white/10 rounded-full transition text-red-400"
                  >
                    <LogOut className="w-5 h-5" />
                  </button>
                </div>
              ) : (
                <Link
                  to="/auth"
                  className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded-lg transition"
                >
                  <User className="w-4 h-4" />
                  <span>Sign In</span>
                </Link>
              )}
              
              <button 
                onClick={() => setIsMobileMenuOpen(true)}
                className="md:hidden p-2 hover:bg-white/10 rounded-full transition"
              >
                <Menu className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <SearchOverlay isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
      <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    </>
  );
}