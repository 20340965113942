import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useNotificationStore } from './notificationStore';

export interface Podcast {
  id: string;
  title: string;
  description: string;
  hostId: string;
  hostName: string;
  hostAvatar?: string;
  category: string;
  coverImage: string;
  status: 'pending' | 'approved' | 'rejected' | 'live' | 'ended';
  createdAt: Date;
  scheduledTime?: Date;
  isLiked?: boolean;
}

interface PodcastState {
  podcasts: Podcast[];
  currentPodcast: Podcast | null;
  isLive: boolean;
  listeners: number;
  likes: Record<string, string[]>;
  submitApplication: (data: {
    title: string;
    description: string;
    category: string;
    coverImage: File | null;
    userId: string;
  }) => Promise<void>;
  approvePodcast: (podcastId: string) => void;
  rejectPodcast: (podcastId: string) => void;
  startStream: (podcastId: string) => void;
  endStream: (podcastId: string) => void;
  toggleLike: (podcastId: string) => void;
  getLikes: (podcastId: string) => number;
  getPendingApplications: () => Podcast[];
  getApprovedPodcasts: () => Podcast[];
  getLivePodcasts: () => Podcast[];
}

export const usePodcastStore = create<PodcastState>()(
  persist(
    (set, get) => ({
      podcasts: [],
      currentPodcast: null,
      isLive: false,
      listeners: 0,
      likes: {},

      submitApplication: async ({ title, description, category, coverImage, userId }) => {
        const imageUrl = coverImage 
          ? URL.createObjectURL(coverImage)
          : `https://source.unsplash.com/random/800x800?podcast&sig=${Math.random()}`;

        const newPodcast: Podcast = {
          id: Math.random().toString(36).substr(2, 9),
          title,
          description,
          hostId: userId,
          hostName: 'User Name', // This should come from user profile
          category,
          coverImage: imageUrl,
          status: 'pending',
          createdAt: new Date()
        };

        set((state) => ({
          podcasts: [...state.podcasts, newPodcast]
        }));

        const { addNotification } = useNotificationStore.getState();
        addNotification({
          type: 'schedule',
          title: 'Podcast Application Received',
          message: 'Your application is under review. We\'ll notify you once it\'s approved.',
        });
      },

      approvePodcast: (podcastId) => {
        set((state) => ({
          podcasts: state.podcasts.map((podcast) =>
            podcast.id === podcastId
              ? { ...podcast, status: 'approved', scheduledTime: new Date() }
              : podcast
          )
        }));
      },

      rejectPodcast: (podcastId) => {
        set((state) => ({
          podcasts: state.podcasts.map((podcast) =>
            podcast.id === podcastId
              ? { ...podcast, status: 'rejected' }
              : podcast
          )
        }));
      },

      startStream: (podcastId) => {
        set((state) => ({
          podcasts: state.podcasts.map((podcast) =>
            podcast.id === podcastId
              ? { ...podcast, status: 'live' }
              : podcast
          ),
          currentPodcast: state.podcasts.find((p) => p.id === podcastId) || null,
          isLive: true,
          listeners: Math.floor(Math.random() * 1000) + 100
        }));
      },

      endStream: (podcastId) => {
        set((state) => ({
          podcasts: state.podcasts.map((podcast) =>
            podcast.id === podcastId
              ? { ...podcast, status: 'ended' }
              : podcast
          ),
          currentPodcast: null,
          isLive: false,
          listeners: 0
        }));
      },

      toggleLike: (podcastId) => {
        const userId = 'current-user-id'; // This should come from auth store
        set((state) => {
          const currentLikes = state.likes[podcastId] || [];
          const hasLiked = currentLikes.includes(userId);

          return {
            likes: {
              ...state.likes,
              [podcastId]: hasLiked
                ? currentLikes.filter((id) => id !== userId)
                : [...currentLikes, userId]
            }
          };
        });
      },

      getLikes: (podcastId) => {
        const state = get();
        return (state.likes[podcastId] || []).length;
      },

      getPendingApplications: () => {
        return get().podcasts.filter((podcast) => podcast.status === 'pending');
      },

      getApprovedPodcasts: () => {
        return get().podcasts.filter((podcast) => podcast.status === 'approved');
      },

      getLivePodcasts: () => {
        return get().podcasts.filter((podcast) => podcast.status === 'live');
      }
    }),
    {
      name: 'podcast-storage'
    }
  )
);