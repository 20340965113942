import React, { useEffect, useRef } from 'react';
import { useRadioStore } from '../../store/radioStore';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';

export function RadioPlayer() {
  const { 
    currentStation, 
    isPlaying, 
    volume, 
    setPlaying, 
    setVolume 
  } = useRadioStore();

  const handlePlayPause = () => {
    setPlaying(!isPlaying);
  };

  const toggleMute = () => {
    setVolume(volume > 0 ? 0 : 0.8);
  };

  return (
    <div className="flex items-center gap-4">
      <button
        onClick={handlePlayPause}
        className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center hover:bg-blue-600 transition"
      >
        {isPlaying ? (
          <Pause className="w-5 h-5" />
        ) : (
          <Play className="w-5 h-5" />
        )}
      </button>
      
      <div className="flex items-center gap-2">
        <button
          onClick={toggleMute}
          className="p-2 hover:bg-gray-700 rounded-full transition"
        >
          {volume === 0 ? (
            <VolumeX className="w-5 h-5" />
          ) : (
            <Volume2 className="w-5 h-5" />
          )}
        </button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={(e) => setVolume(parseFloat(e.target.value))}
          className="w-24"
        />
      </div>
    </div>
  );
}