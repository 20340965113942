import React from 'react';
import { UserProfile } from '../components/Profile/UserProfile';
import { ListeningHistory } from '../components/Profile/ListeningHistory';
import { UserPlaylists } from '../components/Profile/UserPlaylists';
import { UserStats } from '../components/Profile/UserStats';

export function ProfilePage() {
  return (
    <div className="px-4 py-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-1">
            <UserProfile />
            <UserStats />
          </div>
          <div className="lg:col-span-2 space-y-8">
            <ListeningHistory />
            <UserPlaylists />
          </div>
        </div>
      </div>
    </div>
  );
}