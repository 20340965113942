import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface Notification {
  id: string;
  type: 'music' | 'ad' | 'schedule';
  title: string;
  message: string;
  timestamp: Date;
  scheduledTime?: Date;
  link?: string;
  read: boolean;
}

interface NotificationState {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id' | 'timestamp' | 'read'>) => void;
  clearNotification: (id: string) => void;
  markAsRead: (id: string) => void;
  getUnreadCount: () => number;
  clearAll: () => void;
}

export const useNotificationStore = create<NotificationState>()(
  persist(
    (set, get) => ({
      notifications: [],

      addNotification: (notification) => {
        const newNotification: Notification = {
          ...notification,
          id: Math.random().toString(36).substr(2, 9),
          timestamp: new Date(),
          read: false,
        };

        set((state) => ({
          notifications: [newNotification, ...state.notifications].slice(0, 50), // Keep last 50 notifications
        }));
      },

      clearNotification: (id) => {
        set((state) => ({
          notifications: state.notifications.filter((n) => n.id !== id),
        }));
      },

      markAsRead: (id) => {
        set((state) => ({
          notifications: state.notifications.map((n) =>
            n.id === id ? { ...n, read: true } : n
          ),
        }));
      },

      getUnreadCount: () => {
        const state = get();
        return state.notifications.filter((n) => !n.read).length;
      },

      clearAll: () => {
        set({ notifications: [] });
      }
    }),
    {
      name: 'notification-storage',
      version: 1,
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ notifications: state.notifications }),
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            notifications: Array.isArray(persistedState.notifications)
              ? persistedState.notifications.map((n: any) => ({
                  ...n,
                  read: n.read ?? false,
                  timestamp: n.timestamp ? new Date(n.timestamp) : new Date(),
                  scheduledTime: n.scheduledTime ? new Date(n.scheduledTime) : undefined
                }))
              : []
          };
        }
        return persistedState;
      }
    }
  )
);