import { Howl } from 'howler';

class AudioService {
  private currentSound: Howl | null = null;
  private volume: number = 0.8;

  playAudio(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.currentSound) {
        this.currentSound.unload();
      }

      this.currentSound = new Howl({
        src: [url],
        html5: true,
        volume: this.volume,
        format: ['mp3'],
        onplay: () => resolve(),
        onloaderror: (_, error) => reject(error),
        onplayerror: (_, error) => reject(error)
      });

      this.currentSound.play();
    });
  }

  pause() {
    if (this.currentSound) {
      this.currentSound.pause();
    }
  }

  resume() {
    if (this.currentSound) {
      this.currentSound.play();
    }
  }

  stop() {
    if (this.currentSound) {
      this.currentSound.stop();
      this.currentSound.unload();
      this.currentSound = null;
    }
  }

  setVolume(volume: number) {
    this.volume = volume;
    if (this.currentSound) {
      this.currentSound.volume(volume);
    }
  }

  getVolume(): number {
    return this.volume;
  }

  isPlaying(): boolean {
    return this.currentSound ? this.currentSound.playing() : false;
  }
}

export const audioService = new AudioService();