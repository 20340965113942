import { supabase } from '../lib/supabase/client';
import type { Database } from '../lib/supabase/types';

type Track = Database['public']['Tables']['tracks']['Row'];

class TrackService {
  async uploadTrack(data: {
    title: string;
    artist: string;
    genre: string;
    audioUrl: string;
    coverUrl?: string;
  }) {
    const { data: track, error } = await supabase
      .from('tracks')
      .insert([{
        title: data.title,
        artist: data.artist,
        genre: data.genre,
        audio_url: data.audioUrl,
        cover_url: data.coverUrl,
        status: 'approved' // Auto-approve for demo
      }])
      .select()
      .single();

    if (error) throw error;
    return track;
  }

  async getApprovedTracks() {
    const { data, error } = await supabase
      .from('tracks')
      .select('*')
      .eq('status', 'approved')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  }

  async incrementPlayCount(trackId: string) {
    const { error } = await supabase.rpc('increment_play_count', {
      track_id: trackId
    });

    if (error) throw error;
  }
}

export const trackService = new TrackService();