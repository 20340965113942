import { create } from 'zustand';

interface ShareState {
  shareContent: (title: string, text: string, url?: string) => Promise<void>;
}

export const useShareStore = create<ShareState>(() => ({
  shareContent: async (title: string, text: string, url?: string) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title,
          text,
          url: url || window.location.href
        });
      } else {
        // Fallback for browsers that don't support Web Share API
        const shareText = `${title}\n${text}\n${url || window.location.href}`;
        await navigator.clipboard.writeText(shareText);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing content:', error);
    }
  }
}));