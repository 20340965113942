import { create } from 'zustand';
import { useSubscriptionStore } from './subscriptionStore';

interface AudioState {
  audioContext: AudioContext | null;
  gainNode: GainNode | null;
  equalizer: BiquadFilterNode | null;
  compressor: DynamicsCompressorNode | null;
  isInitialized: boolean;
  volume: number;
  initialize: () => void;
  setVolume: (volume: number) => void;
  setAudioQuality: (quality: 'standard' | 'high' | 'ultra') => void;
}

export const useAudioStore = create<AudioState>((set, get) => ({
  audioContext: null,
  gainNode: null,
  equalizer: null,
  compressor: null,
  isInitialized: false,
  volume: 0.8,

  initialize: () => {
    if (get().isInitialized) return;
    
    const audioContext = new AudioContext();
    const gainNode = audioContext.createGain();
    const equalizer = audioContext.createBiquadFilter();
    const compressor = audioContext.createDynamicsCompressor();

    // Set up audio processing chain
    gainNode.connect(equalizer);
    equalizer.connect(compressor);
    compressor.connect(audioContext.destination);
    gainNode.gain.value = get().volume;

    // Initialize equalizer
    equalizer.type = 'lowshelf';
    equalizer.frequency.value = 100;
    equalizer.gain.value = 0;

    // Initialize compressor
    compressor.threshold.value = -24;
    compressor.knee.value = 30;
    compressor.ratio.value = 12;
    compressor.attack.value = 0.003;
    compressor.release.value = 0.25;
    
    set({ 
      audioContext, 
      gainNode,
      equalizer,
      compressor,
      isInitialized: true 
    });

    // Set initial audio quality based on subscription
    const quality = useSubscriptionStore.getState().getAudioQuality();
    get().setAudioQuality(quality);
  },

  setVolume: (volume) => {
    const { gainNode } = get();
    if (gainNode) {
      gainNode.gain.value = volume;
      set({ volume });
    }
  },

  setAudioQuality: (quality) => {
    const { equalizer, compressor } = get();
    if (!equalizer || !compressor) return;

    switch (quality) {
      case 'ultra':
        equalizer.frequency.value = 100;
        equalizer.gain.value = 3;
        compressor.threshold.value = -24;
        compressor.ratio.value = 12;
        break;
      case 'high':
        equalizer.frequency.value = 100;
        equalizer.gain.value = 2;
        compressor.threshold.value = -18;
        compressor.ratio.value = 8;
        break;
      case 'standard':
        equalizer.frequency.value = 100;
        equalizer.gain.value = 0;
        compressor.threshold.value = -12;
        compressor.ratio.value = 4;
        break;
    }
  }
}));