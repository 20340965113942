import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Radio, Mic, Volume2, VolumeX } from 'lucide-react';
import { useNewsStore } from '../store/newsStore';
import { useAdStore } from '../store/adStore';
import { useMusicUploadStore } from '../store/musicUploadStore';
import { useContentStore } from '../store/contentStore';
import { usePlayerStore } from '../store/playerStore';
import { useSubscriptionStore } from '../store/subscriptionStore';
import { openAIService } from '../services/openAIService';

interface Announcement {
  id: string;
  type: 'news' | 'song' | 'ad' | 'greeting' | 'uploaded';
  content: string;
  timestamp: Date;
  track?: any;
}

export function AIDJPresenter() {
  const [currentAnnouncement, setCurrentAnnouncement] = useState<Announcement | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isAnnouncing, setIsAnnouncing] = useState(false);
  const announcementTimerRef = useRef<number>();
  const clearTimerRef = useRef<number>();

  const { articles } = useNewsStore();
  const { getActiveAds, updateAnnouncementCount } = useAdStore();
  const { getNextScheduledTrack, markAsPlayed, getApprovedTracks } = useMusicUploadStore();
  const { setCurrentContent } = useContentStore();
  const { isPlaying, setPlaying } = usePlayerStore();
  const { showsAds, getCurrentPlan } = useSubscriptionStore();

  useEffect(() => {
    // Cleanup function to clear any pending timers
    return () => {
      if (announcementTimerRef.current) {
        window.clearTimeout(announcementTimerRef.current);
      }
      if (clearTimerRef.current) {
        window.clearTimeout(clearTimerRef.current);
      }
    };
  }, []);

  const makeAnnouncement = async () => {
    if (isAnnouncing || isMuted || isPlaying) return;

    const types: ('news' | 'song' | 'ad' | 'greeting' | 'uploaded')[] = ['greeting'];
    const activeAds = showsAds() ? getActiveAds() : [];
    
    if (articles.length > 0) types.push('news');
    if (getApprovedTracks().length > 0) types.push('uploaded');
    if (activeAds.length > 0) types.push('ad');

    const type = activeAds.length > 0 ? 'ad' : types[Math.floor(Math.random() * types.length)];
    let content = '';
    let track;

    setIsAnnouncing(true);

    const useHighQuality = getCurrentPlan().audioQuality !== 'standard';

    try {
      switch (type) {
        case 'news':
          const article = articles[Math.floor(Math.random() * articles.length)];
          content = await openAIService.generateAnnouncement('intro', {
            type: 'news',
            title: article.title
          });
          break;

        case 'uploaded':
          const communityTracks = getApprovedTracks();
          if (communityTracks.length > 0) {
            const uploadedTrack = communityTracks[Math.floor(Math.random() * communityTracks.length)];
            content = await openAIService.generateAnnouncement('intro', {
              title: uploadedTrack.title,
              artist: uploadedTrack.artist,
              type: 'community'
            });
            track = uploadedTrack;
          }
          break;

        case 'ad':
          if (activeAds.length > 0) {
            const ad = activeAds[0];
            content = `Quick message from our sponsor: ${ad.text}`;
            updateAnnouncementCount(ad.id);
          }
          break;

        case 'greeting':
          const hour = new Date().getHours();
          const timeOfDay = hour < 12 ? 'morning' : hour < 18 ? 'afternoon' : 'evening';
          content = `Good ${timeOfDay}! You're tuned in to AI Radio, where the music never stops.`;
          break;
      }

      if (!content) {
        setIsAnnouncing(false);
        return;
      }

      const announcement: Announcement = {
        id: Math.random().toString(36).substr(2, 9),
        type,
        content,
        timestamp: new Date(),
        track
      };

      setCurrentAnnouncement(announcement);

      await openAIService.generateSpeech(content, useHighQuality);
      
      if (track) {
        announcementTimerRef.current = window.setTimeout(() => {
          setCurrentContent({
            id: track.id,
            title: track.title,
            artist: track.artist,
            type: 'music',
            duration: track.duration || 0,
            imageUrl: track.coverUrl || `https://source.unsplash.com/random/800x800?music&sig=${track.id}`,
            audioUrl: track.audioUrl,
            description: `Played on AI Radio`,
            tags: track.genre ? [track.genre] : [],
            likes: track.playCount * 10,
            plays: track.playCount,
            createdAt: new Date(track.uploadedAt)
          });
          setPlaying(true);
        }, 2000);
      }
    } catch (error) {
      console.error('Speech generation failed:', error);
    }

    clearTimerRef.current = window.setTimeout(() => {
      setCurrentAnnouncement(null);
      setIsAnnouncing(false);
    }, 5000);
  };

  useEffect(() => {
    if (!isPlaying && !isAnnouncing) {
      announcementTimerRef.current = window.setTimeout(makeAnnouncement, 3000);
    }
    
    return () => {
      if (announcementTimerRef.current) {
        window.clearTimeout(announcementTimerRef.current);
      }
    };
  }, [isPlaying, isAnnouncing]);

  return (
    <div className="fixed bottom-24 left-0 right-0 z-40 pointer-events-none">
      <div className="max-w-4xl mx-auto px-4">
        <AnimatePresence mode="wait">
          {currentAnnouncement && (
            <motion.div
              key={currentAnnouncement.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-black/80 backdrop-blur-lg rounded-lg p-4 shadow-lg pointer-events-auto"
            >
              <div className="flex items-start gap-4">
                <div className={`rounded-full p-2 ${
                  currentAnnouncement.type === 'ad' ? "bg-green-500" :
                  currentAnnouncement.type === 'news' ? "bg-red-500" :
                  currentAnnouncement.type === 'uploaded' ? "bg-purple-500" :
                  currentAnnouncement.type === 'greeting' ? "bg-yellow-500" :
                  "bg-blue-500"
                }`}>
                  {currentAnnouncement.type === 'news' ? (
                    <Radio className="w-5 h-5" />
                  ) : (
                    <Mic className="w-5 h-5" />
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-white">{currentAnnouncement.content}</p>
                </div>
                <button
                  onClick={() => setIsMuted(!isMuted)}
                  className="text-white hover:text-blue-400 transition pointer-events-auto"
                >
                  {isMuted ? (
                    <VolumeX className="w-5 h-5" />
                  ) : (
                    <Volume2 className="w-5 h-5" />
                  )}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}