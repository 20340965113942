import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { Player } from './components/Player';
import { HomePage } from './pages/HomePage';
import { PlansPage } from './pages/PlansPage';
import { SubmitAdPage } from './pages/SubmitAdPage';
import { NewsPage } from './pages/NewsPage';
import { UploadMusicPage } from './pages/UploadMusicPage';
import { PodcastPage } from './pages/PodcastPage';
import { ProfilePage } from './pages/ProfilePage';
import { AuthPage } from './pages/AuthPage';
import { AIDJPresenter } from './components/AIDJPresenter';
import { useAuthStore } from './store/authStore';

export default function App() {
  const { session } = useAuthStore();

  if (!session) {
    return <AuthPage />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black text-white">
      <Navigation />
      <main className="pt-20 pb-32">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/plans" element={<PlansPage />} />
          <Route path="/submit-ad" element={<SubmitAdPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/upload" element={<UploadMusicPage />} />
          <Route path="/podcast" element={<PodcastPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </main>
      <Player />
      <AIDJPresenter />
    </div>
  );
}