import React from 'react';
import { usePodcastStore } from '../../store/podcastStore';
import { useAuthStore } from '../../store/authStore';
import { useNotificationStore } from '../../store/notificationStore';
import { Check, X, AlertCircle, Radio } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export function PodcastAdmin() {
  const { getPendingApplications, podcasts, approvePodcast, rejectPodcast } = usePodcastStore();
  const { addNotification } = useNotificationStore();
  const { user } = useAuthStore();
  const pendingApplications = getPendingApplications();

  const handleApprove = (podcastId: string) => {
    approvePodcast(podcastId);
    
    // Find the podcast to get details for notification
    const podcast = podcasts.find(p => p.id === podcastId);
    if (podcast) {
      addNotification({
        type: 'schedule',
        title: 'Podcast Application Approved',
        message: `The podcast "${podcast.title}" has been approved and is ready to go live!`,
        link: '/podcast'
      });
    }
  };

  const handleReject = (podcastId: string) => {
    rejectPodcast(podcastId);
    
    // Find the podcast to get details for notification
    const podcast = podcasts.find(p => p.id === podcastId);
    if (podcast) {
      addNotification({
        type: 'schedule',
        title: 'Podcast Application Rejected',
        message: `Unfortunately, your podcast "${podcast.title}" application was not approved.`,
        link: '/podcast'
      });
    }
  };

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <div className="bg-gray-800 rounded-lg p-6">
      <div className="flex items-center gap-3 mb-6">
        <h2 className="text-xl font-bold">Admin Panel</h2>
        <span className="px-2 py-1 bg-blue-500/20 text-blue-400 rounded-full text-xs">
          Admin Only
        </span>
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Pending Applications</h3>
        <AnimatePresence mode="popLayout">
          {pendingApplications.length === 0 ? (
            <div className="text-center py-8">
              <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-400">No pending applications</p>
            </div>
          ) : (
            <div className="space-y-4">
              {pendingApplications.map((podcast) => (
                <motion.div
                  key={podcast.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="bg-gray-700 rounded-lg p-4"
                >
                  <div className="flex items-start gap-4 mb-4">
                    <div className="w-16 h-16 rounded-lg overflow-hidden shrink-0">
                      <img
                        src={podcast.coverImage}
                        alt={podcast.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium mb-1">{podcast.title}</h3>
                      <p className="text-sm text-gray-400 mb-2">by {podcast.hostName}</p>
                      <p className="text-sm text-gray-300">{podcast.description}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                    <span className="text-sm text-gray-400">
                      Applied {new Date(podcast.createdAt).toLocaleDateString()}
                    </span>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleApprove(podcast.id)}
                        className="flex items-center gap-2 px-3 py-1 bg-green-500/10 text-green-500 rounded-lg hover:bg-green-500/20 transition"
                      >
                        <Check className="w-4 h-4" />
                        Approve
                      </button>
                      <button
                        onClick={() => handleReject(podcast.id)}
                        className="flex items-center gap-2 px-3 py-1 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition"
                      >
                        <X className="w-4 h-4" />
                        Reject
                      </button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          )}
        </AnimatePresence>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Quick Actions</h3>
        <div className="grid grid-cols-2 gap-4">
          <button className="p-4 bg-gray-700 rounded-lg hover:bg-gray-600 transition text-left">
            <Radio className="w-5 h-5 text-blue-400 mb-2" />
            <h4 className="font-medium mb-1">View Live</h4>
            <p className="text-sm text-gray-400">Monitor active podcasts</p>
          </button>
          <button className="p-4 bg-gray-700 rounded-lg hover:bg-gray-600 transition text-left">
            <AlertCircle className="w-5 h-5 text-yellow-400 mb-2" />
            <h4 className="font-medium mb-1">Reports</h4>
            <p className="text-sm text-gray-400">View user reports</p>
          </button>
        </div>
      </div>
    </div>
  );
}