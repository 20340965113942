import React from 'react';
import { motion } from 'framer-motion';

interface RadioWaveProps {
  frequency: string;
  isPlaying: boolean;
}

export function RadioWave({ frequency, isPlaying }: RadioWaveProps) {
  return (
    <div className="relative h-32 bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900 rounded-lg overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-4xl font-bold text-white/90">{frequency} FM</span>
      </div>
      
      {isPlaying && (
        <div className="absolute inset-0">
          <div className="flex items-center justify-center h-full gap-1">
            {Array.from({ length: 20 }).map((_, i) => (
              <motion.div
                key={i}
                className="w-1 bg-blue-400/50 rounded-full"
                animate={{
                  height: [20, 40, 20],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: i * 0.1,
                  ease: "easeInOut"
                }}
              />
            ))}
          </div>
        </div>
      )}
      
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent" />
    </div>
  );
}