import React, { useState, useEffect } from 'react';
import { Mic, Users, Heart, MessageSquare, Crown } from 'lucide-react';
import { usePodcastStore } from '../../store/podcastStore';
import { useAuthStore } from '../../store/authStore';
import { useSubscriptionStore } from '../../store/subscriptionStore';
import { CommentSection } from './CommentSection';
import { motion, AnimatePresence } from 'framer-motion';

export function PodcastStream() {
  const { currentPodcast, isLive, listeners, toggleLike, getLikes } = usePodcastStore();
  const { user } = useAuthStore();
  const { getCurrentPlan } = useSubscriptionStore();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const isPremium = getCurrentPlan().id !== 'free';

  const handleLike = () => {
    if (!isPremium) {
      setShowUpgradeModal(true);
      return;
    }
    if (currentPodcast) {
      toggleLike(currentPodcast.id);
    }
  };

  if (!currentPodcast) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <Mic className="w-12 h-12 mx-auto mb-4 text-gray-400" />
          <p className="text-gray-400">No live podcast at the moment</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 rounded-lg overflow-hidden">
      <div className="relative aspect-video bg-black">
        {isLive ? (
          <div className="absolute top-4 left-4 flex items-center gap-2">
            <span className="animate-pulse w-3 h-3 bg-red-500 rounded-full" />
            <span className="text-sm font-medium">LIVE</span>
          </div>
        ) : null}
        
        <img
          src={currentPodcast.coverImage}
          alt={currentPodcast.title}
          className="w-full h-full object-cover opacity-50"
        />
        
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src={currentPodcast.hostAvatar}
            alt={currentPodcast.hostName}
            className="w-24 h-24 rounded-full border-4 border-blue-500"
          />
        </div>
      </div>

      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2 className="text-2xl font-bold">{currentPodcast.title}</h2>
            <p className="text-gray-400">Hosted by {currentPodcast.hostName}</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Users className="w-5 h-5 text-blue-400" />
              <span>{listeners.toLocaleString()} listening</span>
            </div>
            <button
              onClick={handleLike}
              className="flex items-center gap-2 hover:text-pink-500 transition"
            >
              <Heart
                className={`w-5 h-5 ${
                  currentPodcast.isLiked ? 'fill-pink-500 text-pink-500' : ''
                }`}
              />
              <span>{getLikes(currentPodcast.id).toLocaleString()}</span>
            </button>
          </div>
        </div>

        <p className="text-gray-300 mb-6">{currentPodcast.description}</p>

        {isPremium ? (
          <CommentSection podcastId={currentPodcast.id} />
        ) : (
          <div className="bg-gray-800 rounded-lg p-6 text-center">
            <Crown className="w-8 h-8 text-yellow-500 mx-auto mb-3" />
            <h3 className="text-lg font-semibold mb-2">Premium Feature</h3>
            <p className="text-gray-400 mb-4">
              Upgrade to Premium to join the conversation and interact with other listeners!
            </p>
            <button
              onClick={() => setShowUpgradeModal(true)}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Upgrade Now
            </button>
          </div>
        )}
      </div>

      <AnimatePresence>
        {showUpgradeModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-gray-800 rounded-lg p-6 max-w-md w-full"
            >
              <h3 className="text-xl font-bold mb-4">Upgrade to Premium</h3>
              <p className="text-gray-300 mb-6">
                Get access to live comments, podcast interactions, and many more premium features!
              </p>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setShowUpgradeModal(false)}
                  className="px-4 py-2 hover:text-gray-300 transition"
                >
                  Maybe Later
                </button>
                <button
                  onClick={() => {
                    setShowUpgradeModal(false);
                    window.location.href = '/plans';
                  }}
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                  View Plans
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}