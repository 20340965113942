import React from 'react';
import { Clock, Play, MoreHorizontal } from 'lucide-react';
import { format } from 'date-fns';

export function ListeningHistory() {
  const recentTracks = [
    {
      id: '1',
      title: 'Summer Nights',
      artist: 'Dream Wave',
      playedAt: new Date(Date.now() - 1000 * 60 * 30),
      imageUrl: 'https://source.unsplash.com/random/800x800?music&sig=1'
    },
    {
      id: '2',
      title: 'Midnight Drive',
      artist: 'Neon Pulse',
      playedAt: new Date(Date.now() - 1000 * 60 * 45),
      imageUrl: 'https://source.unsplash.com/random/800x800?music&sig=2'
    },
    {
      id: '3',
      title: 'Ocean Breeze',
      artist: 'Coastal Vibes',
      playedAt: new Date(Date.now() - 1000 * 60 * 60),
      imageUrl: 'https://source.unsplash.com/random/800x800?music&sig=3'
    }
  ];

  return (
    <div className="bg-gray-800 rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold">Recently Played</h3>
        <button className="text-sm text-blue-400 hover:text-blue-300">
          View All
        </button>
      </div>

      <div className="space-y-4">
        {recentTracks.map((track) => (
          <div
            key={track.id}
            className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-lg transition group"
          >
            <div className="relative w-12 h-12">
              <img
                src={track.imageUrl}
                alt={track.title}
                className="w-full h-full object-cover rounded-md"
              />
              <button className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition">
                <Play className="w-6 h-6" />
              </button>
            </div>
            <div className="flex-1 min-w-0">
              <div className="font-medium truncate">{track.title}</div>
              <div className="text-sm text-gray-400 truncate">{track.artist}</div>
            </div>
            <div className="flex items-center gap-4 text-gray-400">
              <div className="flex items-center gap-1 text-sm">
                <Clock className="w-4 h-4" />
                {format(track.playedAt, 'HH:mm')}
              </div>
              <button className="p-1 hover:bg-gray-600 rounded-full">
                <MoreHorizontal className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}