import { create } from 'zustand';
import { audioService } from '../services/audioService';
import { useMusicUploadStore } from './musicUploadStore';

interface Station {
  id: string;
  name: string;
  frequency: string;
  genre: string;
  description: string;
  isCommunity?: boolean;
}

interface RadioState {
  currentStation: Station | null;
  currentTrack: {
    title: string;
    artist: string;
    audioUrl: string;
  } | null;
  isPlaying: boolean;
  volume: number;
  setStation: (station: Station) => Promise<void>;
  setPlaying: (playing: boolean) => void;
  setVolume: (volume: number) => void;
  playNextTrack: () => Promise<void>;
}

export const useRadioStore = create<RadioState>((set, get) => ({
  currentStation: null,
  currentTrack: null,
  isPlaying: false,
  volume: 0.8,

  setStation: async (station) => {
    set({ currentStation: station });
    if (station.isCommunity) {
      await get().playNextTrack();
    }
  },

  setPlaying: (playing) => {
    if (playing) {
      if (get().currentStation?.isCommunity) {
        if (!get().currentTrack) {
          get().playNextTrack();
        } else {
          audioService.resume();
        }
      }
    } else {
      audioService.pause();
    }
    set({ isPlaying: playing });
  },

  setVolume: (volume) => {
    audioService.setVolume(volume);
    set({ volume });
  },

  playNextTrack: async () => {
    const { getApprovedTracks } = useMusicUploadStore.getState();
    const tracks = getApprovedTracks();

    if (tracks.length === 0) {
      set({ currentTrack: null, isPlaying: false });
      return;
    }

    const randomTrack = tracks[Math.floor(Math.random() * tracks.length)];
    
    try {
      await audioService.playAudio(randomTrack.audioUrl);
      set({
        currentTrack: {
          title: randomTrack.title,
          artist: randomTrack.artist,
          audioUrl: randomTrack.audioUrl
        },
        isPlaying: true
      });
    } catch (error) {
      console.error('Error playing track:', error);
      set({ currentTrack: null, isPlaying: false });
      // Try next track
      await get().playNextTrack();
    }
  }
}));