import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { uploadService } from '../services/uploadService';
import { trackService } from '../services/trackService';
import { useNotificationStore } from './notificationStore';

export interface UploadedTrack {
  id: string;
  title: string;
  artist: string;
  genre: string;
  status: 'pending' | 'approved' | 'rejected';
  uploadedAt: Date;
  playCount: number;
  audioUrl: string;
  coverUrl?: string;
  isScheduled: boolean;
}

interface MusicUploadState {
  uploads: UploadedTrack[];
  uploadTrack: (data: {
    file: File;
    title: string;
    artist: string;
    genre: string;
    coverImage?: File;
  }) => Promise<void>;
  getApprovedTracks: () => UploadedTrack[];
  incrementPlayCount: (trackId: string) => void;
}

export const useMusicUploadStore = create<MusicUploadState>()(
  persist(
    (set, get) => ({
      uploads: [],

      uploadTrack: async ({ file, title, artist, genre, coverImage }) => {
        try {
          // Upload audio file
          const audioUrl = await uploadService.uploadMusic(file);
          
          // Upload cover image if provided
          let coverUrl;
          if (coverImage) {
            coverUrl = await uploadService.uploadCover(coverImage);
          }

          // Create track record in database
          const track = await trackService.uploadTrack({
            title,
            artist,
            genre,
            audioUrl,
            coverUrl
          });

          const newTrack: UploadedTrack = {
            id: track.id,
            title: track.title,
            artist: track.artist,
            genre: track.genre,
            status: track.status,
            uploadedAt: new Date(track.created_at),
            playCount: track.play_count,
            audioUrl: track.audio_url,
            coverUrl: track.cover_url || undefined,
            isScheduled: false
          };

          set((state) => ({
            uploads: [...state.uploads, newTrack]
          }));

          const { addNotification } = useNotificationStore.getState();
          addNotification({
            type: 'music',
            title: 'Track Uploaded Successfully',
            message: `Your track "${title}" has been uploaded and will be played on Community Radio.`
          });
        } catch (error) {
          console.error('Upload error:', error);
          throw error;
        }
      },

      getApprovedTracks: () => {
        return get().uploads.filter((track) => track.status === 'approved');
      },

      incrementPlayCount: async (trackId: string) => {
        try {
          await trackService.incrementPlayCount(trackId);
          set((state) => ({
            uploads: state.uploads.map((track) =>
              track.id === trackId
                ? { ...track, playCount: track.playCount + 1 }
                : track
            )
          }));
        } catch (error) {
          console.error('Error incrementing play count:', error);
        }
      }
    }),
    {
      name: 'music-upload-storage'
    }
  )
);