import React from 'react';
import { SubscriptionPlans } from '../components/SubscriptionPlans';
import { Crown } from 'lucide-react';

export function PlansPage() {
  return (
    <div className="px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 flex items-center justify-center gap-3">
            <Crown className="w-10 h-10 text-yellow-500" />
            Unlock Premium Features
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Elevate your music experience with high-quality audio, ad-free listening, and exclusive AI-powered features
          </p>
        </div>
        <SubscriptionPlans />
        
        <div className="mt-12 text-center space-y-4">
          <p className="text-gray-400">
            All plans include access to our core features and growing music library
          </p>
          <p className="text-gray-400">
            Premium and Pro plans can be cancelled anytime. No hidden fees.
          </p>
        </div>
      </div>
    </div>
  );
}