import React from 'react';
import { Plus, Music, Play } from 'lucide-react';

export function UserPlaylists() {
  const playlists = [
    {
      id: '1',
      name: 'Chill Vibes',
      trackCount: 42,
      coverUrl: 'https://source.unsplash.com/random/800x800?music&sig=4'
    },
    {
      id: '2',
      name: 'Workout Mix',
      trackCount: 28,
      coverUrl: 'https://source.unsplash.com/random/800x800?music&sig=5'
    },
    {
      id: '3',
      name: 'Focus Flow',
      trackCount: 35,
      coverUrl: 'https://source.unsplash.com/random/800x800?music&sig=6'
    }
  ];

  return (
    <div className="bg-gray-800 rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold">Your Playlists</h3>
        <button className="flex items-center gap-2 px-4 py-2 bg-blue-500 rounded-lg hover:bg-blue-600 transition">
          <Plus className="w-4 h-4" />
          Create Playlist
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {playlists.map((playlist) => (
          <div
            key={playlist.id}
            className="group bg-gray-700 rounded-lg overflow-hidden hover:bg-gray-600 transition"
          >
            <div className="aspect-square relative">
              <img
                src={playlist.coverUrl}
                alt={playlist.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition flex items-center justify-center">
                <button className="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center hover:bg-blue-600 transition transform scale-90 group-hover:scale-100">
                  <Play className="w-6 h-6" />
                </button>
              </div>
            </div>
            <div className="p-4">
              <h4 className="font-medium mb-1">{playlist.name}</h4>
              <div className="flex items-center gap-2 text-sm text-gray-400">
                <Music className="w-4 h-4" />
                {playlist.trackCount} tracks
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}