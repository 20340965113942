import React from 'react';
import { Radio, Music } from 'lucide-react';
import { useRadioStore } from '../../store/radioStore';
import { RadioWave } from './RadioWave';
import { RadioPlayer } from './RadioPlayer';
import { CommunityChannel } from '../CommunityRadio/CommunityChannel';
import { motion } from 'framer-motion';

const stations = [
  {
    id: 'community',
    name: 'Community Radio',
    frequency: '91.5',
    genre: 'Mixed',
    description: 'Music from our community artists',
    isCommunity: true
  },
  {
    id: 'classical',
    name: 'Classical FM',
    frequency: '93.1',
    genre: 'Classical',
    description: 'Classical masterpieces'
  },
  {
    id: 'jazz',
    name: 'Jazz Cafe',
    frequency: '95.5',
    genre: 'Jazz',
    description: 'Smooth jazz and blues'
  },
  {
    id: 'rock',
    name: 'Rock Radio',
    frequency: '97.2',
    genre: 'Rock',
    description: 'Classic and modern rock'
  }
];

export function StationList() {
  const { 
    currentStation, 
    setStation,
    isPlaying
  } = useRadioStore();

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          {currentStation?.isCommunity ? (
            <CommunityChannel />
          ) : (
            <div className="bg-gray-900 rounded-xl p-6 space-y-6">
              <RadioWave 
                frequency={currentStation?.frequency || "00.0"} 
                isPlaying={isPlaying}
              />
              
              {currentStation && (
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-full bg-blue-500/20 flex items-center justify-center">
                        <Radio className="w-5 h-5 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="font-semibold">{currentStation.name}</h3>
                        <p className="text-sm text-gray-400">{currentStation.description}</p>
                      </div>
                    </div>
                    <RadioPlayer />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="space-y-4">
          {stations.map((station, index) => (
            <motion.button
              key={station.id}
              onClick={() => setStation(station)}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`w-full p-4 rounded-lg transition ${
                currentStation?.id === station.id
                  ? station.isCommunity
                    ? 'bg-purple-500'
                    : 'bg-blue-500'
                  : 'bg-gray-800 hover:bg-gray-700'
              }`}
            >
              <div className="flex items-center gap-3">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  currentStation?.id === station.id
                    ? 'bg-white/20'
                    : 'bg-gray-700'
                }`}>
                  {station.isCommunity ? (
                    <Music className="w-5 h-5" />
                  ) : (
                    <Radio className="w-5 h-5" />
                  )}
                </div>
                <div className="text-left">
                  <div className="flex items-baseline gap-2">
                    <span className="text-2xl font-bold">{station.frequency}</span>
                    <span className="text-sm opacity-75">FM</span>
                  </div>
                  <span className="text-sm">{station.name}</span>
                </div>
              </div>
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
}