import React, { useState } from 'react';
import { Mic, Radio, StopCircle, Users, AlertCircle } from 'lucide-react';
import { usePodcastStore } from '../../store/podcastStore';
import { useAuthStore } from '../../store/authStore';
import { motion } from 'framer-motion';

export function PodcastControls() {
  const { user } = useAuthStore();
  const { podcasts, startStream, endStream, isLive, currentPodcast } = usePodcastStore();
  const [showConfirm, setShowConfirm] = useState(false);

  const userPodcast = podcasts.find(p => p.hostId === user?.id && p.status === 'approved');

  const handleStartStream = () => {
    if (!userPodcast) return;
    startStream(userPodcast.id);
  };

  const handleEndStream = () => {
    if (!currentPodcast) return;
    setShowConfirm(true);
  };

  const confirmEndStream = () => {
    if (!currentPodcast) return;
    endStream(currentPodcast.id);
    setShowConfirm(false);
  };

  if (!user || !userPodcast) return null;

  return (
    <div className="bg-gray-800 rounded-lg p-6 mb-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div className="w-12 h-12 rounded-lg bg-gray-700 flex items-center justify-center">
            <Radio className="w-6 h-6 text-blue-400" />
          </div>
          <div>
            <h3 className="font-medium">{userPodcast.title}</h3>
            <p className="text-sm text-gray-400">Your Approved Podcast</p>
          </div>
        </div>
        {isLive && (
          <div className="flex items-center gap-2">
            <span className="animate-pulse w-2 h-2 bg-red-500 rounded-full" />
            <span className="text-sm font-medium">LIVE</span>
          </div>
        )}
      </div>

      {!isLive ? (
        <button
          onClick={handleStartStream}
          className="w-full bg-blue-500 text-white rounded-lg py-3 font-medium hover:bg-blue-600 transition flex items-center justify-center gap-2"
        >
          <Mic className="w-5 h-5" />
          Go Live Now
        </button>
      ) : (
        <button
          onClick={handleEndStream}
          className="w-full bg-red-500 text-white rounded-lg py-3 font-medium hover:bg-red-600 transition flex items-center justify-center gap-2"
        >
          <StopCircle className="w-5 h-5" />
          End Stream
        </button>
      )}

      {showConfirm && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            className="bg-gray-800 rounded-lg p-6 max-w-md w-full"
          >
            <AlertCircle className="w-12 h-12 text-yellow-500 mx-auto mb-4" />
            <h3 className="text-xl font-bold text-center mb-2">End Stream?</h3>
            <p className="text-gray-400 text-center mb-6">
              Are you sure you want to end your live podcast stream? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowConfirm(false)}
                className="px-4 py-2 text-gray-400 hover:text-white transition"
              >
                Cancel
              </button>
              <button
                onClick={confirmEndStream}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
              >
                End Stream
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}