import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true
});

class OpenAIService {
  async generateSpeech(text: string, useHighQuality = false) {
    if (!text.trim()) return;

    try {
      const mp3 = await openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy",
        input: text,
        speed: 1.1,
      });

      const blob = await mp3.blob();
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);
      
      return new Promise((resolve, reject) => {
        audio.onended = () => {
          URL.revokeObjectURL(url);
          resolve(undefined);
        };
        audio.onerror = reject;
        audio.play();
      });
    } catch (error) {
      console.error('Speech generation failed:', error);
      throw error;
    }
  }

  async generateAnnouncement(type: string, context: any) {
    try {
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are an enthusiastic radio DJ. Keep announcements brief, engaging, and natural."
          },
          {
            role: "user",
            content: `Create a brief radio announcement for ${type}: ${JSON.stringify(context)}`
          }
        ],
        max_tokens: 100,
        temperature: 0.7
      });

      return response.choices[0].message.content || "Now playing some great music!";
    } catch (error) {
      console.error('Error generating announcement:', error);
      return "Now playing some great music!";
    }
  }
}

export const openAIService = new OpenAIService();