import React from 'react';
import { Crown, Sparkles, Music, Zap, Users } from 'lucide-react';
import { useSubscriptionStore, type Plan } from '../store/subscriptionStore';
import { useAuthStore } from '../store/authStore';
import { cn } from '../lib/utils';

export function SubscriptionPlans() {
  const { plans, currentPlan, setPlan, isLoading } = useSubscriptionStore();
  const { isAuthenticated, user } = useAuthStore();

  const handleSubscribe = async (plan: Plan) => {
    if (!isAuthenticated) {
      alert('Please sign in to subscribe');
      return;
    }

    if (plan.id === currentPlan) return;
    
    try {
      // Update subscription in Firebase
      setPlan(plan.id);
      alert(`Successfully subscribed to ${plan.name} plan!`);
    } catch (error) {
      console.error('Subscription error:', error);
      alert('Failed to update subscription. Please try again.');
    }
  };

  const getFeatureIcon = (feature: string) => {
    if (feature.includes('audio')) return <Music className="w-4 h-4" />;
    if (feature.includes('AI')) return <Sparkles className="w-4 h-4" />;
    if (feature.includes('family')) return <Users className="w-4 h-4" />;
    if (feature.includes('Premium')) return <Crown className="w-4 h-4" />;
    return <Zap className="w-4 h-4" />;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {plans.map((plan) => (
        <div
          key={plan.id}
          className={cn(
            "relative rounded-2xl p-8",
            plan.isPopular
              ? "bg-blue-500 text-white"
              : "bg-gray-800 text-white"
          )}
        >
          {plan.isPopular && (
            <div className="absolute -top-4 left-1/2 -translate-x-1/2">
              <div className="bg-yellow-500 text-black text-sm font-medium px-3 py-1 rounded-full flex items-center gap-1">
                <Crown className="w-4 h-4" />
                Most Popular
              </div>
            </div>
          )}

          <div className="text-center mb-8">
            <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
            <div className="flex items-center justify-center gap-1">
              <span className="text-3xl font-bold">
                ${plan.price}
              </span>
              {plan.price > 0 && (
                <span className="text-sm opacity-80">/month</span>
              )}
            </div>
          </div>

          <ul className="space-y-4 mb-8">
            {plan.features.map((feature) => (
              <li key={feature} className="flex items-start gap-3">
                <div className="shrink-0 mt-1">
                  {getFeatureIcon(feature)}
                </div>
                <span>{feature}</span>
              </li>
            ))}
          </ul>

          <button
            onClick={() => handleSubscribe(plan)}
            disabled={isLoading || plan.id === currentPlan || !isAuthenticated}
            className={cn(
              "w-full py-3 px-4 rounded-lg font-medium transition",
              plan.isPopular
                ? "bg-white text-blue-500 hover:bg-gray-100"
                : "bg-blue-500 text-white hover:bg-blue-600",
              (isLoading || plan.id === currentPlan || !isAuthenticated) && "opacity-50 cursor-not-allowed"
            )}
          >
            {!isAuthenticated ? 'Sign in to Subscribe' :
              plan.id === currentPlan ? 'Current Plan' : 'Subscribe'}
          </button>

          {plan.id === 'premium' && (
            <div className="mt-4 text-center text-sm opacity-80">
              Includes 14-day free trial
            </div>
          )}
        </div>
      ))}
    </div>
  );
}