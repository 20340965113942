import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Comment {
  id: string;
  podcastId: string;
  userId: string;
  userName: string;
  userAvatar?: string;
  content: string;
  timestamp: Date;
}

interface CommentState {
  comments: Comment[];
  addComment: (comment: Omit<Comment, 'id'>) => void;
  getComments: (podcastId: string) => Comment[];
  deleteComment: (commentId: string) => void;
}

export const useCommentStore = create<CommentState>()(
  persist(
    (set, get) => ({
      comments: [],

      addComment: (comment) => {
        const newComment: Comment = {
          ...comment,
          id: Math.random().toString(36).substr(2, 9)
        };

        set((state) => ({
          comments: [...state.comments, newComment]
        }));
      },

      getComments: (podcastId) => {
        return get().comments
          .filter((comment) => comment.podcastId === podcastId)
          .sort((a, b) => 
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          );
      },

      deleteComment: (commentId) => {
        set((state) => ({
          comments: state.comments.filter((comment) => comment.id !== commentId)
        }));
      }
    }),
    {
      name: 'comment-storage'
    }
  )
);