import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface Plan {
  id: string;
  name: string;
  price: number;
  features: string[];
  isPopular?: boolean;
  audioQuality: 'standard' | 'high' | 'ultra';
  maxSkipsPerHour: number;
  showsAds: boolean;
  allowsDownloads: boolean;
  allowsPlaylists: boolean;
  familyAccess: boolean;
}

export const plans: Plan[] = [
  {
    id: 'free',
    name: 'Free',
    price: 0,
    features: [
      'Ad-supported streaming',
      'Basic AI recommendations',
      'Standard audio quality',
      'Limited skips (3 per hour)'
    ],
    audioQuality: 'standard',
    maxSkipsPerHour: 3,
    showsAds: true,
    allowsDownloads: false,
    allowsPlaylists: false,
    familyAccess: false
  },
  {
    id: 'premium',
    name: 'Premium',
    price: 9.99,
    features: [
      'Ad-free streaming',
      'Advanced AI recommendations',
      'High-quality audio',
      'Unlimited skips',
      'Offline listening',
      'Custom playlists'
    ],
    isPopular: true,
    audioQuality: 'high',
    maxSkipsPerHour: Infinity,
    showsAds: false,
    allowsDownloads: true,
    allowsPlaylists: true,
    familyAccess: false
  },
  {
    id: 'pro',
    name: 'Pro',
    price: 14.99,
    features: [
      'Everything in Premium',
      'Ultra-high quality audio',
      'Early access to features',
      'Priority support',
      'Exclusive AI DJ voices',
      'Family sharing (up to 6 accounts)'
    ],
    audioQuality: 'ultra',
    maxSkipsPerHour: Infinity,
    showsAds: false,
    allowsDownloads: true,
    allowsPlaylists: true,
    familyAccess: true
  }
];

interface SubscriptionState {
  plans: Plan[];
  currentPlan: string;
  skipsUsed: number;
  lastSkipReset: Date;
  isLoading: boolean;
  setPlan: (planId: string) => void;
  setLoading: (loading: boolean) => void;
  canSkipTrack: () => boolean;
  incrementSkips: () => void;
  resetSkips: () => void;
  getCurrentPlan: () => Plan;
  showsAds: () => boolean;
  getAudioQuality: () => 'standard' | 'high' | 'ultra';
  canDownload: () => boolean;
  canCreatePlaylists: () => boolean;
  hasFamilyAccess: () => boolean;
}

export const useSubscriptionStore = create<SubscriptionState>()(
  persist(
    (set, get) => ({
      plans,
      currentPlan: 'free',
      skipsUsed: 0,
      lastSkipReset: new Date(),
      isLoading: false,

      setPlan: (planId) => set({ currentPlan: planId }),
      setLoading: (loading) => set({ isLoading: loading }),
      
      getCurrentPlan: () => {
        const { currentPlan, plans } = get();
        return plans.find(p => p.id === currentPlan) || plans[0];
      },

      canSkipTrack: () => {
        const { skipsUsed, lastSkipReset, getCurrentPlan } = get();
        const plan = getCurrentPlan();
        
        // Reset skips if an hour has passed
        const hourAgo = new Date(Date.now() - 60 * 60 * 1000);
        if (new Date(lastSkipReset) < hourAgo) {
          get().resetSkips();
          return true;
        }

        return skipsUsed < plan.maxSkipsPerHour;
      },

      incrementSkips: () => {
        set((state) => ({
          skipsUsed: state.skipsUsed + 1
        }));
      },

      resetSkips: () => {
        set({
          skipsUsed: 0,
          lastSkipReset: new Date()
        });
      },
      
      showsAds: () => {
        return get().getCurrentPlan().showsAds;
      },
      
      getAudioQuality: () => {
        return get().getCurrentPlan().audioQuality;
      },
      
      canDownload: () => {
        return get().getCurrentPlan().allowsDownloads;
      },
      
      canCreatePlaylists: () => {
        return get().getCurrentPlan().allowsPlaylists;
      },
      
      hasFamilyAccess: () => {
        return get().getCurrentPlan().familyAccess;
      }
    }),
    {
      name: 'subscription-storage',
      version: 1,
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        currentPlan: state.currentPlan,
        skipsUsed: state.skipsUsed,
        lastSkipReset: state.lastSkipReset
      }),
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            currentPlan: persistedState.currentPlan || 'free',
            skipsUsed: 0,
            lastSkipReset: new Date()
          };
        }
        return persistedState;
      }
    }
  )
);