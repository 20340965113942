import React from 'react';
import { Clock, Music, Heart, Radio } from 'lucide-react';

export function UserStats() {
  return (
    <div className="bg-gray-800 rounded-lg p-6">
      <h3 className="text-lg font-semibold mb-4">Listening Stats</h3>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-gray-700 rounded-lg p-4">
          <Clock className="w-8 h-8 text-blue-400 mb-2" />
          <div className="text-2xl font-bold">127h</div>
          <div className="text-sm text-gray-400">Total Listening Time</div>
        </div>
        <div className="bg-gray-700 rounded-lg p-4">
          <Music className="w-8 h-8 text-purple-400 mb-2" />
          <div className="text-2xl font-bold">842</div>
          <div className="text-sm text-gray-400">Tracks Played</div>
        </div>
        <div className="bg-gray-700 rounded-lg p-4">
          <Heart className="w-8 h-8 text-red-400 mb-2" />
          <div className="text-2xl font-bold">156</div>
          <div className="text-sm text-gray-400">Favorites</div>
        </div>
        <div className="bg-gray-700 rounded-lg p-4">
          <Radio className="w-8 h-8 text-green-400 mb-2" />
          <div className="text-2xl font-bold">24</div>
          <div className="text-sm text-gray-400">Radio Stations</div>
        </div>
      </div>
    </div>
  );
}