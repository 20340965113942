import React from 'react';
import { Check, X, Music, AlertCircle } from 'lucide-react';
import { useMusicUploadStore } from '../../store/musicUploadStore';
import { useAuthStore } from '../../store/authStore';
import { motion, AnimatePresence } from 'framer-motion';

export function MusicApproval() {
  const { user } = useAuthStore();
  const { getPendingTracks, approveTrack, rejectTrack } = useMusicUploadStore();
  const pendingTracks = getPendingTracks();

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <div className="bg-gray-800 rounded-lg p-6">
      <div className="flex items-center gap-3 mb-6">
        <h2 className="text-xl font-bold">Music Approval</h2>
        <span className="px-2 py-1 bg-blue-500/20 text-blue-400 rounded-full text-xs">
          Admin Only
        </span>
      </div>

      <AnimatePresence mode="popLayout">
        {pendingTracks.length === 0 ? (
          <div className="text-center py-8">
            <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-400">No pending tracks</p>
          </div>
        ) : (
          <div className="space-y-4">
            {pendingTracks.map((track) => (
              <motion.div
                key={track.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-gray-700 rounded-lg p-4"
              >
                <div className="flex items-start gap-4 mb-4">
                  <div className="w-16 h-16 bg-gray-600 rounded-lg flex items-center justify-center shrink-0">
                    {track.coverUrl ? (
                      <img
                        src={track.coverUrl}
                        alt={track.title}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    ) : (
                      <Music className="w-8 h-8 text-gray-400" />
                    )}
                  </div>
                  <div className="flex-1">
                    <h3 className="font-medium mb-1">{track.title}</h3>
                    <p className="text-sm text-gray-400 mb-2">{track.artist}</p>
                    <p className="text-sm text-gray-300">Genre: {track.genre}</p>
                  </div>
                </div>

                <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                  <audio
                    controls
                    className="w-64"
                    src={track.audioUrl}
                  >
                    Your browser does not support the audio element.
                  </audio>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => approveTrack(track.id)}
                      className="flex items-center gap-2 px-3 py-1 bg-green-500/10 text-green-500 rounded-lg hover:bg-green-500/20 transition"
                    >
                      <Check className="w-4 h-4" />
                      Approve
                    </button>
                    <button
                      onClick={() => rejectTrack(track.id)}
                      className="flex items-center gap-2 px-3 py-1 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition"
                    >
                      <X className="w-4 h-4" />
                      Reject
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}