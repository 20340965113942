import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Content } from './contentStore';

interface FavoriteState {
  favorites: Content[];
  addFavorite: (content: Content) => void;
  removeFavorite: (id: string) => void;
  isFavorite: (id: string) => boolean;
}

export const useFavoriteStore = create<FavoriteState>()(
  persist(
    (set, get) => ({
      favorites: [] as Content[],
      
      addFavorite: (content) => {
        set((state) => ({
          favorites: [...state.favorites, content]
        }));
      },
      
      removeFavorite: (id) => {
        set((state) => ({
          favorites: state.favorites.filter((item) => item.id !== id)
        }));
      },
      
      isFavorite: (id) => {
        const state = get();
        return Array.isArray(state.favorites) && 
          state.favorites.some((item) => item.id === id);
      }
    }),
    {
      name: 'favorite-storage',
      version: 1,
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ favorites: state.favorites }),
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          // Handle migration from version 0 to 1
          return {
            ...persistedState,
            favorites: Array.isArray(persistedState.favorites) 
              ? persistedState.favorites 
              : []
          };
        }
        return persistedState as FavoriteState;
      }
    }
  )
);